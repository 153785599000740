<template>
  <div class="course-statistics"  v-loading="courseStatisticsLoading">
    <!-- title -->
    <div class="course-statistics-title">
      课程统计
    </div>
    <!-- tab -->
    <div class="course-statistics-tab">
      <div class="course-statistics-tab-item" v-for="(item,index) in statisticsList" :key="index"
           @click="clickTabItem(index)">
        <div class="course-statistics-tab-img" style="z-index: 999">
          <img class="tab_img" :src="item.img" alt="">
          <img class="tab_img_sel" :src="item.img_sel" alt="">
        </div>
        <div class="course-statistics-tab-details" style="z-index: 999">
          <div class="title">{{ item.title }}</div>
          <div class="hour">{{ item.hour }}
            <span>学时</span>
          </div>
        </div>
      </div>

      <div class="marker">
      </div>
      <div class="marker_transparent">
      </div>
    </div>
    <!-- 内容切换 -->
    <div class="course-statistics-content">
      <div class="course-statistics-content-border" v-if="subMsg">
        <div class="course-statistics-content-line">
          <div class="title">
            <span>{{this.title}}</span>
          </div>
          <!-- <div class="more" v-if="title = '已选课程'">
            更多>>
          </div> -->
        </div>
        <div class="course-statistics-content-sub">
          <div class="title">课件名称</div>
          <div class="hour">学时</div>
        </div>
        <div class="line">
          <div class="line-sub" v-for="(item,index) in subMsg">
            <div class="title-sub">{{item.courseName}}</div>
            <div class="hour-sub">{{item.creditHours}}</div>
          </div>
        </div>
      </div>
      <div class="course-statistics-content-border">
        <div class="course-statistics-content-line">
          <div class="title">
            已申报学时
          </div>
          <!-- <div class="more">
            更多>>
          </div> -->
        </div>
        <div class="course-statistics-content-sub">
          <div class="title">课件名称</div>
          <div class="hour">学时</div>
        </div>
        <div class="line">
          <div class="line-sub" v-for="(item,index) in declaredMsg">
            <div class="title-sub">{{item.courseName}}</div>
            <div class="hour-sub">{{item.creditHours}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { httpNPGet,httpGet  } from "@/utils/httpRequest";
export default { 
  name: "CourseStatistics",
  data() {
    return {
      courseStatisticsLoading: true,
      statisticsList: [
        {
          img: require('@/assets/img/pre/icon-course-selected-blue.png'),
          img_sel: require('@/assets/img/pre/icon-course-selected-white.png'),
          title: '已选学时',
          hour: '0'
        },
        {
          img_sel: require('@/assets/img/pre/icon-course-none-white.png'),
          img: require('@/assets/img/pre/icon-course-none-blue.png'),
          title: '未完成学时',
          hour: '0'
        },
        {
          img_sel: require('@/assets/img/pre/icon-course-over-white.png'),
          img: require('@/assets/img/pre/icon-course-over-blue.png'),
          title: '已完成学时',
          hour: '0'
        },
        {
          img: require('@/assets/img/pre/icon-course-noti-blue.png'),
          img_sel: require('@/assets/img/pre/icon-course-noti-white.png'),
          title: '已申报学时',
          hour: '0'
        }
      ],
      courseStatisticsData:"",
      hours: {
        allHours:'',
        declareHours:'',
        finishHours:'',
        unfinishedHours:'',
      },
      subIndex:"",
      title:"已选课程",
      subMsg:"",
      declaredMsg:""
    }
  },
  mounted() {
    this.clickTabItem(0);
    this.getStatisticMsg()
    // this.getSubMsg();
  },
  methods: {
    clickTabItem(index) {
      this.subIndex = index
      document.getElementsByClassName('title')[index].style.color = 'white'
      document.getElementsByClassName('hour')[index].style.color = 'white'
      document.getElementsByClassName('tab_img')[index].style.display = 'none'
      document.getElementsByClassName('tab_img_sel')[index].style.display = 'block';
      document.getElementsByClassName('marker')[0].style = 'margin-left:' + 229 * index + 'px';
      document.getElementsByClassName('marker_transparent')[0].style = 'margin-left:' + (229 * index+30) + 'px';
      for (let i = 0; i < this.statisticsList.length; i++) {
        if (i !== index) {
          document.getElementsByClassName('tab_img')[i].style.display = 'block'
          document.getElementsByClassName('tab_img_sel')[i].style.display = 'none';
          document.getElementsByClassName('title')[i].style.color = '#667280'
          document.getElementsByClassName('hour')[i].style.color = '#3E454D'
        }
      }
      this.subIndexChange(index)
    },
    // 修改数据
    subIndexChange(index){
      if(index==0){
        this.subMsg = this.courseStatisticsData.allCourse
        this.title = "已选课程"
      }else if(index==1){
        this.subMsg = this.courseStatisticsData.unfinishedCourse
        this.title = "已完成学时"
      }else if(index==2){
        this.subMsg = this.courseStatisticsData.finishCourse
        this.title = "未完成学时"
      }else if(index==3){
        this.subMsg = ""
      }
    },
    // 获取统计数据
    getStatisticMsg(){
      httpGet("/app/app-user-credit-hours/statistic",
      {
      },(res)=>{
        // console.log(res)
          this.courseStatisticsData = res.data
          // console.log(res.data)
          this.declaredMsg = this.courseStatisticsData.declareCourse

          this.statisticsList[0].hour = res.data.allHours
          this.statisticsList[1].hour = res.data.unfinishedHours
          this.statisticsList[2].hour = res.data.finishHours
          this.statisticsList[3].hour = res.data.declareHours

          this.subIndexChange(0)
          // 关闭加载
          this.courseStatisticsLoading = false
      },(err)=>{
        if(err.code==500){
          if(err.msg=="token异常，请重新登录"){
              localStorage.clear();
              if(!localStorage.getItem("userId")){
                this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login")
              }
            }
        }
        // // console.log(err)
        throw err
      })
    },
    // 获取课程信息
    getSubMsg(){
      httpNPGet("/course-info/list",
        (res) => {
          // console.log(res)
        }, (err) => {
          // // console.log(err)
          throw err
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.course-statistics {
  padding: 0 32px;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .course-statistics-title {
    width: 100%;
    text-align: left;
    height: 64px;
    line-height: 64px;
    font-size: 15px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: #000000;
    border-bottom: 2px solid #E4E7ED;
    margin-bottom: 20px;
  }

  .course-statistics-tab {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    .course-statistics-tab-item {
      width: 23.6095%;
      height: 5rem;
      background: rgba(9, 112, 213, 0.1);
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .course-statistics-tab-img {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 3.84615%;
        margin-left: 6.25%;

        .tab_img {
          display: block;
        }

        .tab_img_sel {
          display: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .course-statistics-tab-details {
        .title {
          height: 1.0625rem;
          font-size: 0.75rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #667280;
          line-height: 0.875rem;
          text-align: left;
        }

        .hour {
          height: 2.125rem;
          font-size: 1.5rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #3E454D;
          line-height: 1.75rem;

          span {
            height: 0.9375rem;
            font-size: 0.6875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 0.8125rem;
          }
        }
      }

    }

    .marker {
      width: 211.75px;
      height: 5rem;
      position: absolute;
      background: linear-gradient(322deg, #1371E7 0%, rgba(19, 113, 231, 0.78) 100%);
      border-radius: 4px 4px 4px 4px;
    }

    .marker_transparent {
      width: 0px;
      height: 0px;
      border-top: 9px solid #1371E7;
      border-bottom: 9px solid transparent;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      margin-top: 4.9375rem;
    }
  }

  .course-statistics-content {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    .course-statistics-content-border {
      border: 1px solid #EDEFF2;
      padding: 0 1.7026%;
      width: 100%;
      min-width: 40%;
      height: 36rem;

      .course-statistics-content-line {
        display: flex;
        height: 4rem;
        line-height: 4rem;
        justify-content: space-between;

        .title {
          text-align: left;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
        }

        .more {
          font-size: 0.8125rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1371E7;
        }
      }

      .course-statistics-content-sub {
        display: flex;
        justify-content: space-between;
        height: 2.0625rem;
        background: #EBECF0;
        align-items: center;
        font-size: 0.75rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #667280;
        line-height: 0.875rem;
        padding: 0 3.72093%;
      }

      .line {
        height: 29.75rem;
        overflow-y: auto;

        .line-sub {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.0625rem solid #EDEFF2;
          height: 2.75rem;

          .title-sub {
            width: 81.17359%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 1.25rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3E454D;
            line-height: 1rem;
            padding-left: 1rem;
            text-align: left;
          }

          .hour-sub {
            height: 1.25rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #3E454D;
            line-height: 1rem;
            padding-right: 1rem;
          }
        }

        .line-sub:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>